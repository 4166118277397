<template>
  <div class="item_list">
    <van-nav-bar
      title="团建申请"
      left-arrow
      safe-area-inset-top
      @click-left="navBack"
    />
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        name="teamtime"
        :value="dateS"
        label="团建时间"
        placeholder="点击选择日期"
        :rules="[{ required: true }]"
        @click="showCalendar = true"
      />
      <van-calendar
        v-model="showCalendar"
        color="#07c160"
        title="选择团建时间日期"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onDateConfirm"
      />
      <van-popup v-model="showDateTimePicker" position="bottom">
        <van-datetime-picker
          v-model="date"
          title="选择时间-日期-小时"
          type="datehour"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onTimeConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        name="place"
        :value="areavalue"
        label="地区选择"
        placeholder="点击选择省市区"
        :rules="[{ required: true }]"
        @click="showArea = true"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          value="450105"
          :area-list="areaList"
          @confirm="onAreaConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        v-model="location"
        name="address"
        label="详细地址"
        placeholder="请填写详细地址"
        :rules="[{ required: true }]"
        class="mt-10"
      />
      <van-field
        v-model="numberS"
        readonly
        clickable
        name="num"
        label="团队人数"
        placeholder="请输入人数"
        :rules="[{ required: true }]"
        @touchstart.native.stop="showNumpad = true"
      />
      <van-number-keyboard
        v-model="number"
        :show="showNumpad"
        :maxlength="4"
        @blur="blurNumpad"
      />
      <van-field
        v-model="activities"
        name="type"
        label="活动形式"
        placeholder="请输入活动形式"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="comment"
        rows="2"
        name="notice"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入备注信息"
      />
      <div class="m16 mt-30">
        <van-button
          :loading="isLogining"
          block
          type="primary"
          native-type="submit"
        >
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  Divider,
  Form,
  Field,
  Calendar,
  Area,
  Popup,
  Toast,
  Button,
  Dialog,
  NavBar,
  DatetimePicker,
  NumberKeyboard
} from 'vant'
// eslint-disable-next-line no-unused-vars
import { format, DATE_FMT, DATE_TIME_FMT } from '@/utils/date'
import { teamBuildLog } from '@/api/home'
import AreaList from '@/utils/area'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Form.name]: Form,
    [Toast.name]: Toast,
    [DatetimePicker.name]: DatetimePicker,
    [Divider.name]: Divider,
    [Dialog.Component.name]: Dialog.Component,
    [NumberKeyboard.name]: NumberKeyboard,
    [Area.name]: Area,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [Field.name]: Field
  },
  data() {
    return {
      date: new Date(),
      showCalendar: false,
      showDateTimePicker: false,
      showNumpad: false,
      isLogining: false,
      minDate: new Date(),
      maxDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      areavalue: '',
      showArea: false,
      areaList: AreaList,
      location: '',
      dateConfirm: '',
      number: '',
      activities: '',
      comment: '',
      isConfirm: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isConfirm) {
      to.meta.isApply = true
    }
    next()
  },
  computed: {
    dateS() {
      return format(this.date, DATE_TIME_FMT)
    },
    numberS() {
      return this.number === '' ? '' : `${this.number}人`
    }
  },
  methods: {
    onDateConfirm(date) {
      console.log('onDateConfirm', date)
      this.date = date
      this.showCalendar = false
      this.showDateTimePicker = true
    },
    onTimeConfirm(date) {
      console.log('onDateConfirm', date)
      this.dateConfirm = format(date, DATE_TIME_FMT)
      console.log(this.dateConfirm)
      this.showDateTimePicker = false
    },
    onAreaConfirm(values) {
      console.log('onAreaConfirm', values)
      this.areavalue = values
        .filter(item => !!item)
        .map(item => item.name)
        .join(' ')
      this.showArea = false
    },
    blurNumpad() {
      console.log('blurNumpad')
      this.number = this.number.replace(/^0+/, '')
      this.showNumpad = false
    },
    async onSubmit(values) {
      values = { ...values, ...{ people: parseInt(this.number) } }
      delete values.num
      // console.log('submit', values)
      this.isLogining = true
      // const loading = this.$loading()
      try {
        await this.applyTeamBuild(values)
      } catch (err) {
        console.log(err)
        Toast(err)
      } finally {
        this.isLogining = false
      }
    },
    async applyTeamBuild(apdata) {
      console.log(apdata)
      const data = await teamBuildLog(apdata)
      console.log(data)
      if (data.status === 1) {
        console.log('申请成功')
        Dialog.alert({
          title: '申请成功',
          message: '团建申请已成功提交，请等待审核'
        }).then(() => {
          this.isConfirm = true
          this.navBack()
        })
      } else {
        throw data.msg
      }
    },
    navBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.mt-10 {
  margin-top: 10px;
}
.m16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}
</style>
